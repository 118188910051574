// List.jsx
import React from "react";
import "./List.css";
import useFetch from "../../hooks/useFetch";
import Card from "../Card/Card";

const List = ({
  brands,
  categories,
  packaging,
  maxPrice,
  sort,
  page,
  onPageChange,
}) => {
  const offset = (page - 1) * 10; // Assuming a limit of 10 items per page

  const { data, loading, error } = useFetch(
    `/products?populate=*` +
      `${
        brands.length > 0
          ? `&[filters][brands][id][$eq]=${brands.join(",")}`
          : ""
      }` +
      `${
        categories.length > 0
          ? `&[filters][categories][id][$eq]=${categories.join(",")}`
          : ""
      }` +
      `${
        packaging.length > 0
          ? `&[filters][packagings][id][$eq]=${packaging.join(",")}`
          : ""
      }&[filters][price][$lte]=${maxPrice}&sort=price:${sort}&offset=${offset}`
  );

  return (
    <div className="list">
      {loading
        ? "Loading..."
        : data?.map((item) => <Card item={item} key={item.id} />)}
      {/* Pagination Controls
      {data && data.meta && (
        <div className="pagination">
          <button onClick={() => onPageChange(page - 1)} disabled={page === 1}>
            Prev
          </button>
          <span>
            Page {page} of {Math.ceil(data.meta.total / 10)}
          </span>
          <button
            onClick={() => onPageChange(page + 1)}
            disabled={page === Math.ceil(data.meta.total / 10)}
          >
            Next
          </button>
        </div>
      )} */}
    </div>
  );
};

export default List;
