import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import "./Card.scss";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const Card = ({ item }) => {
  const scrollTop = () => {
    window.scroll(0, 0);
  };


 

  return (
    <>
      <Link to={`/product/${item.id}`} onClick={scrollTop}>
        <div className="card">
          <div className="card_in">
            <div className="image">
              {item.attributes.discount && (
                <span> {item.attributes.discoamount}</span>
              )}
              <span2> {item.attributes.outofstock && "out of stock"} </span2>

              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  item.attributes?.img?.data?.attributes?.url
                }
                alt=""
                className="mainImg"
              />
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  item.attributes?.img2?.data?.attributes?.url
                }
                alt=""
                className="secondImg"
              />
            </div>

            <div className="data">
              <br />
              <p>{item.attributes.title}</p>
              <small> Weight: {item.attributes.weight}</small>
              <div className="prices">
                <h2>{item?.attributes.oldprice}</h2> 
                <h1>
                  <small>AED</small> {item?.attributes.price}
                </h1>
                {/* <h1>
              {
                item?.attributes.price - (Math.round (item?.attributes.price * 15) / 100)}
            </h1>*/}
              </div>
              <button className="addtocart">ADD TO CART</button>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Card;
