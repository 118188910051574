import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import List from "../../components/List/List";
import useFetch from "../../hooks/useFetch";
import "./Products.scss";
import axios from "axios";

const Products = () => {
  const catId = parseInt(useParams().id);
  const [maxPrice, setMaxPrice] = useState(250);
  const [sort, setSort] = useState("asc");
  const [selectedSubCats, setSelectedSubCats] = useState([]);
  const [pkg, setPkg] = useState([]);
  const [brand, setBrand] = useState([]);
  const [product, setProduct] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPackaging, setSelectedPackaging] = useState([]);

  //const { data, loading, error } = useFetch(`/packagings`);

  useEffect(() => {
    brandData();
    productData();
    packData()
  }, []);

  const packData = () => {
    axios
      .get(`https://shop-admin.notionsgroup.com/api/packagings`)
      .then((response) => {
        // Assuming response.data is an array
        setPkg(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const brandData = () => {
    axios
      .get(`https://shop-admin.notionsgroup.com/api/brands`)
      .then((response) => {
        // Assuming response.data is an array
        setBrand(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const productData = () => {
    axios
      .get("https://shop-admin.notionsgroup.com/api/categories")
      .then((response) => {
        // Assuming response.data is an array
        setProduct(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const handleChangeProducts = (e) => {
  //   const value = e.target.value;
  //   const isChecked = e.target.checked;

  //   setSelectedProducts(
  //     isChecked
  //       ? [...selectedProducts, value]
  //       : selectedProducts.filter((item) => item !== value)
  //   );
  // };

  const handleChangeProducts = (e) => {
    const value = e.target.value;

    setSelectedBrand([value]);
  };

  const handleChangeCategories = (e) => {
    const value = e.target.value;

    setSelectedCategories([value]);
  };

  const handleChangePackaging = (e) => {
    const value = e.target.value;

    setSelectedPackaging([value]);
  };

   const clearFilters = () => {
     setSelectedBrand([]);
     setSelectedCategories([]);
     setSelectedPackaging([]);
     setSort("asc");
     // Add additional state resets if needed
   };

  // const handleChangeCategories = (e) => {
  //   const value = e.target.value;
  //   const isChecked = e.target.checked;

  //   setSelectedCategories(
  //     isChecked
  //       ? [...selectedCategories, value]
  //       : selectedCategories.filter((item) => item !== value)
  //   );
  // };

  // const handleChangePackaging = (e) => {
  //   const value = e.target.value;
  //   const isChecked = e.target.checked;

  //   setSelectedPackaging(
  //     isChecked
  //       ? [...selectedPackaging, value]
  //       : selectedPackaging.filter((item) => item !== value)
  //   );
  // };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="products">
      <div className="left">
        <div className="left_con">
          
          <div className="filterItem">
            <div className="heading">
              <h4>Brand</h4>
            </div>
            <div className="inputItem">
              <select onChange={handleChangeProducts} defaultValue="">
                <option value="" disabled>
                  Select Brand
                </option>
                {brand?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.attributes.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="filterItem">
            <div className="heading">
              <h4>Products</h4>
            </div>
            <div className="inputItem">
              <select onChange={handleChangeCategories} defaultValue="">
                <option value="" disabled>
                  Select Products
                </option>
                {product?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.attributes.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="filterItem">
            <div className="heading">
              <h4>Packaging</h4>
            </div>
            <div className="inputItem">
              <select onChange={handleChangePackaging}>
                <option value="" disabled selected>
                  Select Packaging
                </option>
                {pkg?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.attributes.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="filterItem">
            <div className="heading">
              <h4>Sort by</h4>
            </div>

            <div className="inputItem">
              <input
                type="radio"
                id="asc"
                value="asc"
                name="price"
                onChange={(e) => setSort("asc")}
              />
              <label htmlFor="asc">Price (Lowest first)</label>
            </div>
            <div className="inputItem">
              <input
                type="radio"
                id="desc"
                value="desc"
                name="price"
                onChange={(e) => setSort("desc")}
              />
              <label htmlFor="desc">Price (Highest first)</label>
            </div>
            {/* <div>
              <button onClick={clearFilters}>Clear Filters</button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="right">
        {/*<img
          className="catImg"
          src="https://images.pexels.com/photos/1074535/pexels-photo-1074535.jpeg?auto=compress&cs=tinysrgb&w=1600"
          alt=""
        /> */}

        <List
          brands={selectedBrand}
          categories={selectedCategories}
          packaging={selectedPackaging}
          catId={catId}
          maxPrice={maxPrice}
          sort={sort}
          page={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Products;
